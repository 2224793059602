@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');


body {
    background-color: #191919;
}


li { 
    white-space: nowrap;}

@layer base {
    html {
        font-family: 'Nunito Sans', sans-serif;
    }
}

.sortable-helper {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15) !important;
    z-index: 100;
}

.hide-options-container .options-container {
    display: none !important;
}